@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import '~toastr/build/toastr.min.css';

// Pages
@import 'pages/dashboard';
@import 'pages/return-request';
