body.return-request {
    #uploadsPreview {
        .upload {
            .background {
                height: 125px;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 5px;
                border: 1px solid #e0e0e0;
                position: relative;
            }

            .remove-file {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.6);
                padding: 0 6px;
                border-radius: 4px;
            }

            .name {
                background: rgba(255, 255, 255,0.8);
                padding: 2px 10px;
                font-size: 12px;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }
}

